@import "../../../common/variables"
@import "../../../common/mixins"

.instant-payouts
  & .title
    margin-bottom: 30px !important
    font-family: "MacPawFixelDisplay-Bold"
    font-size: 96px
    font-style: normal
    line-height: 115%
    text-transform: uppercase
    margin: 0

    +max-w(768)
      font-size: 40px

  & .notify-block
    padding: 10px 20px
    border-radius: 40px
    background-color: #5405D6
    font-family: "MacPawFixelDisplay-Regular"
    font-size: 22px
    margin-top: 60px

    +max-w(768)
      width: 100%
      border-radius: 10px
      font-family: "MacPawFixelDisplay-Semibold"
      font-size: 18px
      margin-top: 20px

  & .step
    display: flex
    gap: 200px
    padding: 70px 0

    &:not(:last-child)
      border-bottom: 1px solid rgba(255,255,255, 0.4)

    & .number
      min-width: 200px
      max-width: 200px

    & .text
      font-family: "MacPawFixelDisplay-Semibold"
      font-size: 40px

      +max-w(768)
        font-size: 30px

    +max-w(768)
      gap: 0px
      flex-direction: column

  & .info-block
    display: flex
    gap: 32px

    +max-w(768)
      gap: 20px
      flex-direction: column

    & .info-title
      flex: 1
      font-family: "MacPawFixelDisplay-Bold"
      font-size: 40px

      +max-w(768)
        font-size: 18px

    & .info-description
      flex: 1
      font-family: "MacPawFixelDisplay-Regular"
      font-size: 22px

      +max-w(768)
        font-size: 18px
