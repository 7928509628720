.rates {
    padding: 32px 0 55px;
}

@media (max-width: 768px) {
    .rates {
        padding: 21px 0 49px;
    }
}

.rates__title {
    font-size: 64px;
    margin: 0 0 25px;
    color: var(--white) !important;
}

@media (max-width: 768px) {
    .rates__title {
        font-size: 30px !important;
        margin: 0 0 29px;
        text-align: center;
    }
}
.rates-listItem {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.rates-listItem > span {
    display: block;
    flex: 1;
    font-size: clamp(18px, 4vw, 22px);
    color: white;
}
.rates-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .rates-listItem {
        gap: 20px;
    }
    .rates-list {
        grid-template-columns: repeat(1, 1fr);
        margin: 0 -8px;
        gap: 36px;
    }
}

.rates-list__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #544f67;
    background: rgba(255, 255, 255, 0.1);
    padding: 30px 30px 30px 29px;
    height: 88px;
    gap: 10px;
    color: var(--white);
}
.rates-list-item p {
    color: var(--white);
}

.rates-list__box {
    display: flex;
    align-items: center;
    gap: 10px;
}
@media (max-width: 768px) {
    .rates-list__box {
        gap: 5px;
    }
}

.rates-list__img {
    transform: translateY(-2px);
}

.rates-list__block {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
    .rates-list__block {
        gap: 5px;
    }
    .rates-list__block--wrap p {
        flex-wrap: wrap;
    }
}

.rates-list__block span {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
}

.rates-list__block p {
    font-size: 18px;
    font-family: "MacPawFixelDisplay-ExtraBold";
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

.rates-list__per {
    font-size: 18px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
}

.rates-list-item p {
    font-size: 22px;
    font-family: "MacPawFixelDisplay-Regular";
    line-height: normal;
    margin: 0;
    padding-top: 16px;
}

@media (max-width: 768px) {
    .rates-list-item p {
        font-size: 18px;
        padding-top: 0;
    }
}
.rates-limits section {
    background-color: var(--dark);
}
.rates-limits .h2 {
    font-family: "MacPawFixelDisplay-Bold";
    font-size: 96px;
    font-style: normal;
    line-height: 115%;
    text-transform: uppercase;
}

.rates-limits .b-select {
    background-color: transparent;
    justify-content: flex-end;
    padding-right: 25px;
}
.rates-limits .show:not(ul) {
    background-color: #544f67;
}
.rates-limits .b-select-wrapper .show:not(ul) .dropdown-icon {
    fill: var(--white);
    stroke: var(--white);
}
