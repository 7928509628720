@import "../../../common/variables"
@import "../../../common/mixins"

.home_section
  background: var(--dark) !important
  // padding-bottom: 56px
.hero-container
  padding-top: 100px !important
  padding-bottom: 230px !important

  +max-w(768)
    padding-top: 42px !important
    padding-bottom: 60px !important

  background-size: initial

  & .hero-title
    font-size: 54px
    line-height: 145%
    font-family: "MacPawFixelDisplay-Bold", Courier, monospace
    max-width: 710px
    text-transform: uppercase

.cards-item
  margin: 14px 16px
  display: flex
  flex-direction: column
  gap: 8px
  position: relative

  & .currencies-list
    position: absolute
    top: 56px
    left: 0
    right: 0
    bottom: 0
    background-color: #111111
    overflow: hidden
    overflow-y: auto
    border-radius: 10px
    border: 1px solid #383838

  & .currency-button
    display: flex
    gap: 14px
    padding: 12px
    align-self: flex-start
    align-items: center
    border-radius: 10px
    border: 1px solid #383838
    justify-content: space-between

    & .title
      flex: 1
      margin-left: 8px
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-size: 18px
      line-height: 24px
      font-family: "MacPawFixelDisplay-Semibold", Courier, monospace

  & .title
    font-size: 16px
    font-family: "MacPawFixelDisplay-Semibold", Courier, monospace

  & .cards-container
    display: flex
    overflow: hidden
    flex-direction: column
    border: 1px solid #383838
    border-radius: 10px

    & .card-item
      height: 74px
      padding: 14px
      display: flex
      gap: 10px
      align-items: center

      &:not(:last-child)
        border-bottom: 1px solid #212121

      &.active
        background-color: #272727

      &:hover
        background-color: #272727

      & .icon
        width: 40px
        height: 40px
        display: flex
        align-items: center
        justify-content: center
        border-radius: 8px
        background-color: #5405D6

        & > img
          width: 26px
          height: 26px

      & .info
        display: flex
        justify-content: between
        flex-direction: column

        & .title
          font-size: 18px
          font-family: "MacPawFixelDisplay-Semibold", Courier, monospace

        & .description
          font-size: 14px
          color: #8F8F8F
          font-family: "MacPawFixelDisplay-Medium", Courier, monospace

.selector-button
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 6px
  overflow: hidden

  & .code
    line-height: 18px
    font-size: 18px
    font-family: "MacPawFixelDisplay-Semibold", Courier, monospace
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    max-width: 100%

  & .name
    line-height: 12px
    font-size: 12px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    max-width: 100%
    letter-spacing: -2%
    font-family: "MacPawFixelDisplay-Medium", Courier, monospace
