.b-select-wrapper {
    font-family: "MacPawFixelDisplay-Regular";
}
.b-select {
    transition: none !important;
    font-size: 18px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 50px;
    font-weight: 400;
    line-height: 21px;
    color: white;
    padding: 8px 16px;
    background: rgba(0, 0, 0, 1);
    appearance: none;
    outline: none;
    max-width: 100%;
    margin: 0 !important;
    min-height: 47px;
    width: 100%;
    position: relative;
    z-index: 999;
}
.b-select-menu {
    background-clip: border-box !important ;
}
.b-select-menu ul {
    margin-bottom: 0 !important;
}
.centered .b-select {
    justify-content: center;
}
.b-select.error_input {
    border: 1px solid var(--red, rgba(0, 0, 0, 0.15)) !important;
}
.b-select:hover:not(.show) {
    background: var(--b-select-hover-dark);
}
.b-select-wrapper .show:not(ul) {
    background-color: var(--b-select-bg-light);
}
.b-select-wrapper .show:not(ul) .dropdown-icon {
    fill: var(--dark);
    stroke: var(--dark);
}
/* .show:not(ul) {
  background-color: var(--b-select-hover-light);
} */
.show:not(:has(ul)) span {
    color: var(--dark);
}
.BSelectLarge {
    min-height: 65.5px;
}
.connector:has(~ .b-select-menu[data-popper-placement="bottom-start"]) {
    display: block;
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    z-index: 998;
    width: 100%;
    height: 39px;
    background: var(--b-select-list-bg-dark);
}
.connector:has(~ .b-select-menu[data-popper-placement="top-start"]) {
    display: block;
    position: absolute;
    content: "";
    left: 0;
    top: -3px;
    z-index: 998;
    width: 100%;
    height: 50%;
    background: var(--b-select-list-bg-dark);
}

.b-select .dropdown-icon {
    position: absolute;
    right: 8px;
    bottom: 8px;
    fill: white;
    stroke: white;
}
.BSelectLarge .dropdown-icon {
    right: 16px;
    bottom: 16px;
}
.b-select span {
    display: block;
    font-size: 18px;
    color: var(--white);
    margin-bottom: 0 !important;
}
.b-select-wrapper .show button:not(.b-select-menu) {
    background: var(--b-select-hover-light);
    border: 1px solid var(--b-select-hover-light);
}

.show:not(.b-select-menu) .dropdown-icon {
    transform: rotateZ(170deg);
}

.b-select-menu {
    min-width: 100% !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important ;
    background: #393639;
    max-width: 100% !important;
    z-index: 1000;
    max-height: 300px;
    border: 1px solid transparent !important;
    overflow: hidden;
    background:
        linear-gradient(var(--b-select-list-bg-dark), var(--b-select-list-bg-dark)) padding-box,
        linear-gradient(to top, var(--b-select-list-border-light), var(--b-select-list-bg-dark))
            border-box;
}
.b-select-menu ul {
    list-style-type: none;
    padding: 0;
    max-height: calc(300px - 20px);
    overflow: auto;
    overflow-x: auto;
}
.b-select-menu .dropdown-item {
    margin-bottom: 0 !important;
    width: inherit;
    color: var(--white);
}
.b-select-menu li {
    min-height: 47px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.centered .b-select-menu li {
    justify-content: center;
}
.b-select-menu .dropdown-item:hover {
    background: none !important;
}
.dropdownLight .b-select-menu .dropdown-item:hover {
    color: var(--dark) !important;
}
.b-select-menu li:hover,
.b-select-menu li .active {
    background: #ffffff10 !important;
    color: white !important;
}
.b-select-menu[data-popper-placement="top-start"] {
    border-radius: 0 !important;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    background:
        linear-gradient(var(--b-select-list-bg-dark), var(--b-select-list-bg-dark)) padding-box,
        linear-gradient(to bottom, var(--b-select-list-border-light), var(--b-select-list-bg-dark))
            border-box;
}

.dropdownLight .connector {
    background-color: var(--b-select-list-bg-light) !important;
}
.dropdownLight .b-select-menu li:hover,
.dropdownLight .active,
.dropdownLight .b-select-menu li:has(.selected-with-arrow) {
    background: #00000010 !important;
    color: var(--dark) !important;
}
.dropdownLight .b-select-menu .dropdown-item {
    color: var(--dark);
}
.dropdownLight .b-select-menu {
    background:
        linear-gradient(var(--b-select-list-bg-light), var(--b-select-list-bg-light)) padding-box,
        linear-gradient(to top, var(--b-select-list-border-light), var(--b-select-list-bg-light))
            border-box;
}
.dropdownLight .b-select-menu[data-popper-placement="top-start"] {
    border-radius: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background:
        linear-gradient(var(--b-select-list-bg-light), var(--b-select-list-bg-light)) padding-box,
        linear-gradient(to bottom, var(--b-select-list-border-light), var(--b-select-list-bg-light))
            border-box;
}
.dropdownLight .show:not(ul) {
    background-color: var(--b-select-hover-light);
}
.dropdownLight .b-select:hover:not(.show) .dropdown-icon {
    fill: var(--dark);
    stroke: var(--dark);
}

.dropdownLight .b-select span {
    color: var(--dark);
}
.dropdownLight .dropdown-icon {
    fill: var(--dark);
    stroke: var(--dark);
}

.dropdownLight .b-select {
    border: none !important;
    background: var(--white-3);
    color: var(--dark);
}
.dropdownLight .b-select:hover:not(.show) {
    color: var(--dark) !important;
    /* border: 1px solid var(--b-select-hover-light); */
    background-color: var(--b-select-hover-light) !important;
}

.b-select input {
    pointer-events: none;
    appearance: none;
    background-color: transparent;
    width: 100%;
    border: none;
    margin-left: 15px;
}

.no-option {
    display: block;
    text-align: center;
    opacity: 0.5;
}
span:has(~ .dropdown) {
    display: block;
    font-size: 18px;
    opacity: 0.9;
    line-height: normal;
    margin-bottom: 6px !important;
    color: var(--dark);
    margin-left: 31px;
    font-family: "MacPawFixelDisplay-Light";
}
.bs-error-label:first-child {
    color: var(--error) !important;
    opacity: 1 !important;
}
.bs-error-label:last-of-type {
    font-family: "MacPawFixelDisplay-Light";
    color: var(--error);
    font-size: 16px;
    position: absolute;
    bottom: 0;
    opacity: 0.7;
    transform: translateY(100%);
    margin-left: 31px;
}
