@import "../../../common/variables"
@import "../../../common/mixins"

.how-to-sell
  display: flex
  background-color: var(--dark) !important
  padding-bottom: 104px

  .container
    display: flex
    justify-content: flex-end

    .image-content-container
      position: relative
      flex: 1

      .image-content
        width: 1528px
        position: absolute
        top: 0
        left: -146%
        bottom: 0
        display: flex
        align-items: center
        justify-content: center
        background: center / contain no-repeat url(/img/how-to-sell-bg.png)

        +max-w(1199)
          max-width: 1368px

        +max-w(768)
          display: none

    .text-content
      display: flex
      flex-direction: column
      width: 60%

      +max-w(768)
        width: 100%

      & .title
        margin-bottom: 60px !important
        font-family: "MacPawFixelDisplay-Bold"
        font-size: 96px
        font-style: normal
        line-height: 115%
        text-transform: uppercase
        margin: 0

        +max-w(768)
          font-size: 40px

      & .step
        display: flex
        gap: 24px
        align-items: center

        &:not(:last-child)
          margin-bottom: 50px

        +max-w(768)
          gap: 16px
          flex-direction: column
          align-items: flex-end

          & .text-title, & .text
            text-align: right

          &:nth-child(even)
            align-items: flex-start

            & .text-title, & .text
              text-align: left

        & .number
          min-width: 160px
          max-width: 160px

        & .info
          display: flex
          flex-direction: column
          gap: 10px

          +max-w(768)
            gap: 16px
            text-align: right

            &:nth-child(even)
              text-align: left

          & .text-title
            text-transform: uppercase
            font-family: "MacPawFixelDisplay-Bold"
            font-size: 22px

            +max-w(768)
              font-size: 20px

          & .text
            font-family: "MacPawFixelDisplay-Regular"
            font-size: 22px

            +max-w(768)
              font-size: 18px
